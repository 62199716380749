import { useFlags } from "launchdarkly-react-client-sdk";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useFetchContext } from "context/FetchContext/useFetchContext";
import { getSupportDataBFF } from "hooks/api/useBFFApi";
import { getSupportData } from "utils/requests/authedAPI";

const fetchSupportData = (slug, trainingServerAxios) => {
  return trainingServerAxios(getSupportData(slug)).then(
    (response) => response.data
  );
};

const fetchSupportDataBFF = (slug, trainingBFFAxios) => {
  return trainingBFFAxios(getSupportDataBFF(slug)).then(
    (response) => response.data
  );
};

const useSupportData = () => {
  const { supportHelpBarV2 } = useFlags();
  const { trainingServerAxios, trainingBFFAxios } = useFetchContext();
  const location = useLocation();
  const slug = location?.pathname?.split("/")?.[2];
  const response = useQuery(
    ["support", slug, supportHelpBarV2],
    () => {
      if (supportHelpBarV2) {
        return fetchSupportDataBFF(slug, trainingBFFAxios);
      }

      return fetchSupportData(slug, trainingServerAxios);
    },
    {
      enabled: Boolean(slug),
      onError: (e) => {
        throw new Error(e);
      },
      useErrorBoundary: true,
    }
  );
  return response;
};

export { useSupportData };
